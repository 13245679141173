<template>
    <main class="main-container">

        <div v-if="load" class="flex justify-center items-center gap-2">
            <span class="text-xl">Loading</span>
            <i class="fas fa-circle-notch fa-spin text-xl"></i>
        </div>

        <div v-if="error" class="text-red-500 flex justify-center items-center font-bold text-xl">
            Server nicht erreichbar
        </div>

        <div class="wrapper grid sm:grid-cols-2 grid-cols-1 gap-8 lg:grid-cols-3">
            <ProjectCard v-for="project in projects"
                :key="project.id"
                :name="project.name" 
                :desc="project.description" 
                :link="project.link" 
                :icon="`https://res.diafischchen.net/project_icons/${project.icon}`" />
        </div>
    </main>
</template>

<script>
import axios from 'axios';
import ProjectCard from '@/components/ProjectCard.vue';

export default {
    name: 'Projects',
    components: {
        ProjectCard
    },
    data() {
        return {
            projects: [],
            load: true,
            error: false
        }
    },
    async created() {

        if ('projects' in sessionStorage) {

            this.projects = JSON.parse(sessionStorage.getItem('projects'));
            this.load = false;

        } else {

            await axios.get('https://api.diafischchen.net/homepage/projects')
                .then((response) => {
                    response.data.forEach(element => {
                        this.projects.push(element);
                    });

                    sessionStorage.setItem('projects', JSON.stringify(response.data))
                })
                .catch(() => {
                    this.error = true;
                })
                .then(() => {
                    this.load = false;
                })

        }


    }
}
</script>