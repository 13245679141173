<template>
    <a :href="link">
        <figure class="bg-gray-800 rounded-md p-4 gap-4 shadow-md transition transform hover:bg-gray-900 hover:-translate-y-0.5 h-full flex flex-col justify-between sm:items-stretch items-center">
            <div class="fig-img">
                <img class="mx-auto" :src="icon" />
            </div>
            <div class="fig-content text-center sm:text-left">
                <figcaption class="font-bold text-xl mb-2">{{ name }}</figcaption>
                <div class="text-xs">{{ desc }}</div>
            </div>
        </figure>
    </a>
</template>

<script>
export default {
    name: 'ProjectCard',
    props: [
        'name',
        'desc',
        'link',
        'icon'
    ]
}
</script>

<style scoped>

.fig-img {
    @apply mb-4;
}

.fig-img img {
    @apply sm:h-36 sm:w-36 h-20 w-20;
}

</style>